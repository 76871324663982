export default grapesjs.plugins.add('FourTeaserTile', (editor, options) => {
	editor.BlockManager.add('FourTeaserTile', {
		label: 'Four Teasor Tile',
		category: 'Commerce',
		attributes: { class: 'fa fa-gift' },
		content: '<div class="FourTeaserTile TeaserTile" data-gjs-type="FourTeaserTile" data-initial-state="true">configure your content</div>'
	});

	// Get DomComponents module
	var comps = editor.DomComponents;
	
	// Get the model and the view from the default Component type
	var defaultType = comps.getType('default');
	var defaultModel = defaultType.model;
	var defaultView = defaultType.view;

	// The `input` will be the Component type ID
	comps.addType('FourTeaserTile', {
		// Define the Model
		model: defaultModel.extend({
			// Extend default properties
			defaults: Object.assign(
				{},
				defaultModel.prototype.defaults, {
					// Can't drop other elements inside it
					droppable: false,
					// Traits (Settings)
					traits: [
						{
							type: 'text', // Type of the trait
							label: 'Header Title', // The label you will see in Settings
							name: 'headerTitle', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '1. Tile Element-Title', // The label you will see in Settings
							name: 'elTitle1', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '1. Tile Element-Content', // The label you will see in Settings
							name: 'elContent1', // The name of the attribute/property to use on component
						}, {
							type: 'text', // Type of the trait
							label: '1. Tile Link-URL', // The label you will see in Settings
							name: 'linkURL1', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '1. Tile Link-Title', // The label you will see in Settings
							name: 'linkTitle1', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '1. Tile Image URL', // The label you will see in Settings
							name: 'imageURL1', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '2. Tile Element-Title', // The label you will see in Settings
							name: 'elTitle2', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '2. Tile Element-Content', // The label you will see in Settings
							name: 'elContent2', // The name of the attribute/property to use on component
						}, {
							type: 'text', // Type of the trait
							label: '2. Tile Link-URL', // The label you will see in Settings
							name: 'linkURL2', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '2. Tile Link-Title', // The label you will see in Settings
							name: 'linkTitle2', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '2. Tile Image URL', // The label you will see in Settings
							name: 'imageURL2', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '3. Tile Element-Title', // The label you will see in Settings
							name: 'elTitle3', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '3. Tile Element-Content', // The label you will see in Settings
							name: 'elContent3', // The name of the attribute/property to use on component
						}, {
							type: 'text', // Type of the trait
							label: '3. Tile Link-URL', // The label you will see in Settings
							name: 'linkURL3', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '3. Tile Link-Title', // The label you will see in Settings
							name: 'linkTitle3', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '3. Tile Image URL', // The label you will see in Settings
							name: 'imageURL3', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '4. Tile Element-Title', // The label you will see in Settings
							name: 'elTitle4', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '4. Tile Element-Content', // The label you will see in Settings
							name: 'elContent4', // The name of the attribute/property to use on component
						}, {
							type: 'text', // Type of the trait
							label: '4. Tile Link-URL', // The label you will see in Settings
							name: 'linkURL4', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '4. Tile Link-Title', // The label you will see in Settings
							name: 'linkTitle4', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '4. Tile Image URL', // The label you will see in Settings
							name: 'imageURL4', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Is Visible From', // The label you will see in Settings
							name: 'cccStartDate', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Is Visible To', // The label you will see in Settings
							name: 'cccEndDate', // The name of the attribute/property to use on component
						}
					],
					attributes: {
						headerTitle: '',
						elTitle1: '',
						elContent1: '',
						linkURL1: '',
						linkTitle1: '',
						imageURL1: '',
						elTitle2: '',
						elContent2: '',
						linkURL2: '',
						linkTitle2: '',
						imageURL2: '',
						elTitle3: '',
						elContent3: '',
						linkURL3: '',
						linkTitle3: '',
						imageURL3: '',
						elTitle4: '',
						elContent4: '',
						linkURL4: '',
						linkTitle4: '',
						imageURL4: '',
						cccStartDate: '',
						cccEndDate: ''
					}
				}
			),
			init() {
				this.on('change:attributes:headerTitle', this.handleTraitChange);
				this.on('change:attributes:elTitle1', this.handleTraitChange);
				this.on('change:attributes:elTitle2', this.handleTraitChange);
				this.on('change:attributes:elTitle3', this.handleTraitChange);
				this.on('change:attributes:elTitle4', this.handleTraitChange);
				this.on('change:attributes:elContent1', this.handleTraitChange);
				this.on('change:attributes:elContent2', this.handleTraitChange);
				this.on('change:attributes:elContent3', this.handleTraitChange);
				this.on('change:attributes:elContent4', this.handleTraitChange);
				this.on('change:attributes:linkTitle1', this.handleTraitChange);
				this.on('change:attributes:linkTitle2', this.handleTraitChange);
				this.on('change:attributes:linkTitle3', this.handleTraitChange);
				this.on('change:attributes:linkTitle4', this.handleTraitChange);
				this.on('change:attributes:linkURL1', this.handleTraitChange);
				this.on('change:attributes:linkURL2', this.handleTraitChange);
				this.on('change:attributes:linkURL3', this.handleTraitChange);
				this.on('change:attributes:linkURL4', this.handleTraitChange);
				this.on('change:attributes:imageURL1', this.handleTraitChange);
				this.on('change:attributes:imageURL2', this.handleTraitChange);
				this.on('change:attributes:imageURL3', this.handleTraitChange);
				this.on('change:attributes:imageURL4', this.handleTraitChange);
				this.on('change:attributes:cccStartDate', this.handleTraitChange);
				this.on('change:attributes:cccEndDate', this.handleTraitChange);
			},
			handleTraitChange() {
				this.attributes.content = this.generateHTML(false);
				this.view.el.innerHTML = this.generateHTML(false);
				$(this.view.el).removeAttr('data-initial-state');
				delete this.view.attr['data-initial-state'];
				delete this.attributes.attributes['data-initial-state'];
			},
			toHTML: function () {
				return this.generateHTML(true);
			},
			generateHTML(includeParentDOMElement) {

				if (typeof includeParentDOMElement == 'undefined') {
					includeParentDOMElement = false;
				}

				let elementString = '';
				let timedAttributes = '';

				if (includeParentDOMElement) {
					if (this.getAttributes().cccStartDate && this.getAttributes().cccEndDate) {
						timedAttributes = ' cccStartDate="' + this.getAttributes().cccStartDate + '" cccEndDate="' +  this.getAttributes().cccEndDate + '"';
					}
					elementString = elementString + '<div class="w-100 FourTeaserTile TeaserTile" data-gjs-type="FourTeaserTile"' + timedAttributes + '>';
				}

				elementString += `
				<div class="hdm-bg-secondary four-teaser-tile-container">
					<div class="container-fluid four-teaser-tile-content-container">
							<div>
								<h2 class="four-teaser-tile-header">` + this.getAttributes().headerTitle + `</h2>
							</div>
							<div class="row justify-content-center">
							<!--start-->
								<div class="col-xs-8 col-sm-6 col-md-6 col-lg-3 four-teaser-tile-component" >
									<div class="card">
										<div class="card-body four-teaser-tile-background-image" style="background-image:url(`+ (this.getAttributes().imageURL1).trim() + `)">
											<div class="row" >
												<div class="col fixed-bottom position-absolute py-3 four-teaser-tile-content" >
													<div  >
														<h4 class="four-teaser-tile-subtitle">` + this.getAttributes().elTitle1 + `</h4>
													</div>`;

				if (this.getAttributes().elContent1) {
					elementString += `<div>
														<p class="four-teaser-tile-content-text">` + this.getAttributes().elContent1 + `</p>
													</div>`;
				}
				elementString += `<div class="d-flex">
														<i class="icon-forward four-teaser-tile-link-icon mr-3" aria-hidden="true"></i>
														<a href="` + this.getAttributes().linkURL1 + `" class="four-teaser-tile-link">` + this.getAttributes().linkTitle1 + `</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!--end card-->
									<!--start-->
									<div class="col-xs-8 col-sm-6 col-md-6 col-sm-6 col-lg-3 four-teaser-tile-component" >
										<div class="card">
										<div class="card-body four-teaser-tile-background-image" style="background-image:url(`+ this.getAttributes().imageURL2 + `)">
											<div class="row" >
												<div class="col fixed-bottom position-absolute py-3 four-teaser-tile-content" >
													<div  >
														<h4 class="four-teaser-tile-subtitle">` + this.getAttributes().elTitle2 + `</h4>
													</div>
													`;

				if (this.getAttributes().elContent2) {
					elementString += `<div>
										<p class="four-teaser-tile-content-text">` + this.getAttributes().elContent2 + `</p>
									</div>`;
				}
				elementString += `<div class="d-flex">
														<i class="icon-forward four-teaser-tile-link-icon mr-3" aria-hidden="true"></i>
														<a href="` + this.getAttributes().linkURL2 + `" class="four-teaser-tile-link">` + this.getAttributes().linkTitle2 + `</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!--end card-->
									<!--start-->
									<div class="col-xs-8 col-sm-6 col-md-6 col-lg-3 four-teaser-tile-component" >
										<div class="card">
										<div class="card-body four-teaser-tile-background-image" style="background-image:url(`+ this.getAttributes().imageURL3 + `)">
											<div class="row" >
												<div class="col fixed-bottom position-absolute py-3 four-teaser-tile-content" >
													<div  >
														<h4 class="four-teaser-tile-subtitle">` + this.getAttributes().elTitle3 + `</h4>
													</div>
													`;

				if (this.getAttributes().elContent3) {
					elementString += `<div>
														<p class="four-teaser-tile-content-text">` + this.getAttributes().elContent3 + `</p>
													</div>`;
				}
				elementString += `<div class="d-flex">
														<i class="icon-forward four-teaser-tile-link-icon mr-3" aria-hidden="true"></i>
														<a href="` + this.getAttributes().linkURL3 + `" class="four-teaser-tile-link">` + this.getAttributes().linkTitle3 + `</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!--end card-->
									<!--start-->
									<div class="col-xs-8 col-sm-6 col-md-6 col-lg-3 four-teaser-tile-component" >
									<div class="card">
										<div class="card-body four-teaser-tile-background-image" style="background-image:url(`+ (this.getAttributes().imageURL4).trim() + `)">
											<div class="row" >
												<div class="col fixed-bottom position-absolute py-3 four-teaser-tile-content" >
													<div  >
														<h4 class="four-teaser-tile-subtitle">` + this.getAttributes().elTitle4 + `</h4>
													</div>
													`;

				if (this.getAttributes().elContent4) {
					elementString += `<div>
																			<p class="four-teaser-tile-content-text">` + this.getAttributes().elContent4 + `</p>
																		</div>`;
				}
				elementString += ` <div class="d-flex">
																<i class="icon-forward four-teaser-tile-link-icon mr-3" aria-hidden="true"></i>
																<a href="` + this.getAttributes().linkURL4 + `" class="four-teaser-tile-link">` + this.getAttributes().linkTitle4 + `</a>
														</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!--end card-->
							</div>
						</div>
					</div>`;



				if (includeParentDOMElement) {
					elementString += '</div>';
				}
				// setup the header
				// if (this.getAttributes().headerTitle) {
				//     elementString = '<div class="container-fluid four-teaser-tile-container py-3 px-3" ><div class="four-teaser-tile-header py-3"><h2>' + this.getAttributes().headerTitle + '</h2></div>';
				// }

				return elementString;

			}
		},
			// The second argument of .extend are static methods and we'll put inside our
			// isComponent() method. As you're putting a new Component type on top of the stack,
			// not declaring isComponent() might probably break stuff, especially if you extend
			// the default one.
			{
				isComponent(el) {
					if (el.getAttribute && el.getAttribute('data-gjs-type') == 'FourTeaserTile') {
						return {
							type: 'FourTeaserTile'
						};
					}
				}
			}
		)
	});
});